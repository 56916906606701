import { useEffect, useState } from "react";
import { useArchive } from "./useArchive";
import { Navigation } from "../Navigation/Navigation";
import { TagCloud } from "./TagCloud";
import { item } from "../../types";
import { Item } from "./Item";

export const Archive = () => {

  const archive = useArchive();
  const [filteredItems, setItems] = useState<Array<item>>([]);
  const [totalItems, setTotalItems] = useState<Array<item>>([]);
  const [tagCloud, setTags] = useState<Array<string>>([]);
  const [isActive, setActive] = useState<number>(-1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(18);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [total, setTotal] = useState<number>();

  useEffect(() => {

    const items: any =
      archive.data &&
      archive.data.hasOwnProperty("result") &&
      typeof archive.data.result === "object" &&
      archive.data.result.map((d: item, i: number) => d);

    const tags: any =
      archive.data &&
      archive.data.hasOwnProperty("result") &&
      typeof archive.data.result === "object" &&
      archive.data.result.map((d: item, i: number) =>
        d.tags.replace(/\s*,\s*/g, ",").split(",")
      );

    const total: any =
      archive.data &&
      archive.data.hasOwnProperty("result") &&
      typeof archive.data.result === "object" &&
      archive.data.result.length;

    setItems(items);
    setTotalItems(items);
    setTotal(total);

    const merged = [].concat.apply([], tags);
    const unique: any = merged.filter((item, i) => merged.indexOf(item) === i);
    const duplicates = unique.map((value: any) => [
      value,
      merged.filter((str: any) => str === value).length,
    ]);
    setTags(duplicates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive.loading]);

  const filterByTags = (idx: number, id: string) => {

    const items = document.querySelectorAll
    (".archive__container__wrapper__item");

    items.forEach((element: any) => {
      if (element.classList.contains("expanded")) {
        element.classList.remove("expanded");
        element.classList.remove("not-expanded");
      } else {
        element.classList.remove("not-expanded");
      }
    });

    setActive(idx);
    const activeTag = id;

    if (isActive === idx) {
      setActive(-1);
      setItems(totalItems);
      setItemsPerPage(6);
      setIsCompleted(false);
      setCurrentPage(1);
    } else {
      if (activeTag === "all") {
        setItems(totalItems);
        setItemsPerPage(6);
        setIsCompleted(false);
        setCurrentPage(1);
      } else {
        const filteredItems = totalItems.filter((item: item) =>
          item.tags.includes(activeTag)
        );
        setItems(filteredItems);
        const newItems = 6;
        setItemsPerPage(newItems);
        setCurrentPage(1);

        if (filteredItems && newItems >= filteredItems.length) {
          setIsCompleted(true);
        } else {
          setIsCompleted(false);
        }
      }
    }

    

  };

  const expandItem = (e: any) => {

    const parent = e.currentTarget.parentElement;
    const items = document.querySelectorAll
    (".archive__container__wrapper__item");

    if (e.currentTarget.classList.contains("expanded")) {
      e.currentTarget.classList.remove("expanded");
      parent.childNodes.forEach((element: any) => {
        element.classList.remove("not-expanded");
      });
    } else if (e.currentTarget.classList.contains("not-expanded")) {
      parent.childNodes.forEach((element: any) => {
        if (element.classList.contains("expanded")) {
          element.classList.remove("expanded");
          element.classList.add("not-expanded");
        } else {
          element.classList.add("not-expanded");
        }
        e.currentTarget.classList.remove("not-expanded");
        e.currentTarget.classList.add("expanded");
      });
    } else {
      items.forEach((element: any) => {
        if (element.classList.contains("expanded")) {
          element.classList.remove("expanded");
          element.classList.remove("not-expanded");
        } else {
          element.classList.remove("not-expanded");
        }
      });

      e.currentTarget.classList.add("expanded");
      parent.childNodes.forEach((element: any) => {
        if (element.classList.contains("expanded")) {
        } else {
          element.classList.add("not-expanded");
        }
      });
    }
  };

  const splitIntoChunks = (array: any, chunk = 6) => {
    let result = [];
    let i;

    if (array) {
      for (i = 0; i < array.length; i += chunk) {
        result.push(array.slice(i, i + chunk));
      }
    }

    return result;
  };

  const splitedItems = splitIntoChunks(filteredItems, 6);

  const loadMore = () => {
    const oldItems = itemsPerPage;
    const newItems = oldItems + 6;
    setItemsPerPage(newItems);
    if (
      (totalItems && newItems >= totalItems.length) ||
      (splitedItems && newItems >= splitedItems.length)
    ) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  return (
    <>
      {archive.loading ? (
        <span className="loading">Loading...</span>
      ) : (
        <div className="container-archive">
          <Navigation></Navigation>
          <section className="archive">
            <div className="archive__header">
              <TagCloud
                tagCloud={tagCloud}
                isActive={isActive}
                total={total}
                filterByTags={filterByTags}
                {...(filterByTags as any)}
              ></TagCloud>
              <div className="archive__header__caption text--xs">
                This page contains a selection of graphic works, sketched,
                experiments, observations, findings and references.
              </div>
            </div>
            {/* <div className="archive__wrapper">
              {filteredItems && filteredItems 
                  .map((item: item, idx: number) => (
                    <div
                      key={idx}
                      className="archive__wrapper__item"
                      onClick={(e) => {
                        expandItem(e);
                      }}
                    >
                      <Item tags={item.tags} files={item.files}></Item>
                    </div>
               
                  ))}
            </div> */}
            <div className="archive__container">
              {splitedItems &&
                splitedItems.map((arr, idx) => {
                  return (
                    <div key={idx} className="archive__container__wrapper">
                      {arr.map((item: any, idx: any) => (
                        <div
                          key={idx}
                          className="archive__container__wrapper__item"
                          onClick={(e) => {
                            expandItem(e);
                          }}
                        >
                          <Item tags={item.tags} files={item.files}></Item>
                        </div>
                      ))}
                    </div>
                  );
                })}
            </div>
          </section>
          {/* <footer className={!isCompleted ? "active" : ""}>
            <div className="load-more">
              <button className="btn" onClick={loadMore}>
                Load More...
              </button>
            </div>
          </footer> */}
        </div>
      )}
    </>
  );
};
