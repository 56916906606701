import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/main.css";
import { Archive } from "./components/Archive/Archive";
import { Index } from "./components/Index/Index";
import { ServicesPage } from "./components/Services/Services";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index></Index>} />
          <Route path="/archive" element={<Archive></Archive>} />
          <Route path="/services" element={<ServicesPage></ServicesPage>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
