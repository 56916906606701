import { item, file } from "../../types";

export type Props = item;

export const Item = ({ files }: Props) => {
  return (
    <div>
      {files &&
        files.map((f: file, idx: number) => {
          if (f.type === "image") {
            return <img key={idx} src={f.url} alt="" />;
          } else if (f.type === "video") {
            return (
              <video key={idx} playsInline autoPlay muted loop>
                <source src={f.url} />
              </video>
            );
          }
        })}
    </div>
  );
};
