import { Navigation } from "../Navigation/Navigation";
import { useServices } from "./useServices";

export const ServicesPage = () => {
  const services = useServices();

  return (
     <>
     {services.loading ? (<span className="loading">Loading...</span>): (    <>
      <Navigation></Navigation>
      <section className="services">
        {services.data &&
          services.data.hasOwnProperty("result") &&
          typeof services.data.result === "object" && (
            <div
              dangerouslySetInnerHTML={{ __html: services.data.result.about }}
            ></div>
          )}
        <div>
          <u>Design:</u>
          {services.data &&
            services.data.hasOwnProperty("result") &&
            typeof services.data.result === "object" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: services.data.result.design,
                }}
              ></div>
            )}
        </div>
        <div>
          <u>Technical:</u>
          {services.data &&
            services.data.hasOwnProperty("result") &&
            typeof services.data.result === "object" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: services.data.result.technical,
                }}
              ></div>
            )}
        </div>
        <div>
          <u>Follow:</u>
          {services.data &&
            services.data.hasOwnProperty("result") &&
            typeof services.data.result === "object" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: services.data.result.follow,
                }}
              ></div>
            )}
        </div>
      </section>
    </>)}
     </>
  );
};
