import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useToggle } from "../hooks/useToggle";

/* eslint-disable react-hooks/exhaustive-deps */
export type initialData = {
  data: any;
  loading: boolean;
  setLoading: () => void;
};

export const useServices = (): initialData => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useToggle(true);

  
  const api = process.env.REACT_APP_URL;
  const username = process.env.REACT_APP_USER;
  const password = process.env.REACT_APP_PW;


  const getAPIData = async () => {
    const headers = {
      Authorization:
        "Basic " + Buffer.from(`${username}:${password}`).toString("base64"),
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const response = await fetch(`${api}`, {
      method: "post",
      body: JSON.stringify({
        query: "page('services')",
        select: {
          about: "page.about.kirbytext",
          design: "page.design.kirbytext",
          technical: "page.technical.kirbytext",
          follow: "page.follow.kirbytext",
        },
      }),
      headers,
    });

    const json = await response.json();
    setData(json);
    setLoading();
  };

  useEffect(() => {
    if (!loading) return;
    getAPIData();
  }, [loading]);

  return { data, loading, setLoading };
};
