import { useIndex } from "./useIndex";
import { ProjectType } from "../../types";
import { Navigation } from "../Navigation/Navigation";
import { Project } from "./Project/Project";
import { useState } from "react";

export const Index = () => {
  const index = useIndex();
  const [selected, setSelected] = useState<number>(-1);
  const handleClick = (e: any) => {
    setSelected(e);
    if(selected === e){
      setSelected(-1);
    }
  };
  return (
<>
  {index.loading ? (<span className="loading">Loading...</span>):(    <div className="container">
      <Navigation></Navigation>
      <section className="index">
        <div className="index__row text--xs" id="subnav">
          <span className="title">Project</span>
          <span className="category">Category</span>
          <span className="client">Client</span>
          <span className="year">Year</span>
        </div>
        {index.data &&
          index.data.hasOwnProperty("result") &&
          typeof index.data.result === "object" &&
          Array.isArray(index.data.result) &&
          index.data.result.map((d: ProjectType, i: number) => (
            <div className={`index__row ${(selected === i ) ? "active" : ""}`} key={i} onClick={() => handleClick(i)}>
              <span className="title general-project-info">{d.title}</span>
              <span className="category general-project-info">{d.category}</span>
              <span className="client general-project-info">{d.client}</span>
              <span className="year general-project-info">{d.year}</span>
              <Project 
              title={d.title}
              type={d.type}
              materials={d.materials}
              website={d.website}
              links={d.links}
              info={d.info}
              selectedFiles={d.selectedFiles}
              files={d.files}
              ></Project> 
            </div>
          ))}
      </section>
    </div>)}
</>
  );
};
