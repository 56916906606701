import { ProjectType } from "../../../types";
import { Swiper } from "./Swiper";

export type Props = ProjectType;

export const Project = ({
  type,
  materials,
  website,
  links,
  info,
  title,
  selectedFiles,
  files,
}: Props) => {
  return (
    <>
      <div className="project text--xs">
        <div className="project__text">
<div className="project__text__el">
<div className="project__details" id="type">
            <span>Type</span>
            <span>{type}</span>
          </div>
          <div className="project__details" id="materials">
            <span>Materials</span>
            <span>{materials}</span>
          </div>
          {typeof website === "string" && website !== "" ? (
            <div className="project__details" id="website">
              <span>Website</span>
              {typeof title === "string" && (
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
              )}
            </div>
          ) : (
            ""
          )}
          {typeof links === "string" ? (
            <div className="project__details" id="links">
              <span>Links</span>
              <span dangerouslySetInnerHTML={{ __html: links }}></span>
            </div>
          ) : (
            ""
          )}
</div>
          {typeof info === "string" ? (
            <div className="project__details" id="info">
              <div dangerouslySetInnerHTML={{ __html: info }}></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Swiper
          title={title}
          type={type}
          materials={materials}
          website={website}
          links={links}
          info={info}
          files={files}
          selectedFiles={selectedFiles}
        ></Swiper>
      </div>
    </>
  );
};
