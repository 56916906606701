import { SwiperItemType, file } from "../../../types";

export type Props = SwiperItemType;

export const SwiperItem = ({
  filteredFiles,
  current,
  setCurrent,
  length,
}: Props) => {
  const nextSlide = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    filteredFiles &&
      filteredFiles.length > 0 &&
      setCurrent(current === length - 1 ? 0 : current + 1);
      console.log(current)
  };

  return (
    <div className="swiper-container__list__item" onClick={nextSlide}>
      {filteredFiles &&
        filteredFiles.length > 0 &&
        filteredFiles.map((f: file, idx: number) => {
          if (f.type === "image" && idx === current) {
            return (
              <li className="swiper-item" key={idx}>
                <img className="swiper-item__img" src={f.url} alt="" />
              </li>
            );
          } else if (f.type === "video" && idx === current) {
            return (
              <li className="swiper-item" key={idx} >
                <video className="swiper-item__video"playsInline autoPlay muted loop>
                  <source src={f.url} />
                </video>
              </li>
            );
          }
        })}
    </div>
  );
};
