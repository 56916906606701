import {useEffect, useState} from 'react';
import { Data } from '../../types';
import {Buffer} from 'buffer';
import { useToggle } from '../hooks/useToggle';

/* eslint-disable react-hooks/exhaustive-deps */

export type initialData = {
  data: any;
  loading: boolean;
  setLoading: () => void;
};

export const useArchive = () => {
    const [data, setData] = useState<Data>();
    const [loading, setLoading] = useToggle(true);

    const api = process.env.REACT_APP_URL;
    const username = process.env.REACT_APP_USER;
    const password = process.env.REACT_APP_PW;

    const getAPIData = async () => {
        const headers = {
            Authorization: "Basic " + Buffer.from(`${username}:${password}`).toString("base64"),
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          
          const response = await fetch(`${api}`, {
            method: "post",
            body: JSON.stringify({
              "query": "page('archive').children",
              "select": {
                "tags": "page.tags",
                "files": {
                  "query": "page.files",
                  "select": {
                    "url": true,
                    "niceSize": true,
                    "alt": "file.alt.kirbytext",
                    "type": true
                  }
                }
              }
            }),
            headers,
          });
          
          const json = await response.json();
          setData(json)
          setLoading();
        };

      useEffect(() => {
        if (!loading) return;
        getAPIData();
      }, [loading]);

      return {data, loading, setLoading} ;

}