import { Link, NavLink } from "react-router-dom";

export const Navigation = () => {

  return (
    <section className="nav">
      <div className="nav__left">
          <span> SOPHIA MSAOUBI </span>
          <span> GRAPHIC DESIGNER / WEB DEVELOPER </span>
          <span> SOPHIA.MSAOUBI@GMAIL.COM </span>
          <span>last update: 19/10/2023</span>
      </div>
      <div className="nav__right text--xl">
       
        <div className="nav__el page">
         <NavLink className={(navData) => (navData.isActive ? 'active' : 'link')} to="/">Index,</NavLink>
        </div>
        <div className="nav__el page">
        <NavLink  className={(navData) => (navData.isActive ? 'active' : 'link')} to="/archive">Archive,</NavLink>
        </div>
        <div className="nav__el page">
        <NavLink className={(navData) => (navData.isActive ? 'active' : 'link')} to="/services">Info</NavLink>
        </div>
      </div>
    </section>
  );
};
