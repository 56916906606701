import { spawn } from "child_process";
import { file, SwiperItemType } from "../../../types";

export type Props = SwiperItemType;

export const SwiperCount = ({ filteredFiles, current , setCurrent}: Props) => {

  const handleClick = (e: any, idx: number) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrent(idx);
  }
  return (
    <div className="swiper-container__count">
   {filteredFiles && 
   filteredFiles.length > 1 &&
   filteredFiles.map((f: file, idx: number) =>(
     <span className={`item-idx ${idx} ${(idx === current) ? "active":""}`} key={idx} 
     onClick={(e) => 
      handleClick(e, idx)}>
        {idx + 1}
        </span>
   ))
   }
    </div>
  );
};
