import { TagCloudType } from "../../types";

export type Props = TagCloudType;

export const TagCloud = ({tagCloud, isActive, total,filterByTags}: Props) => {
  
    return(
     <div className="archive__header__tag-cloud">
        <button
          className={`archive__header__tag-cloud__tag ${isActive === -1 ? "active" : ""}`}
          id="all"
          onClick={(e: any) => filterByTags(-1, e.target.id)}
        >
          All({total})
        </button>
        {tagCloud.map((tag: string, idx: number) => (
          <button
            className={`archive__header__tag-cloud__tag ${isActive === idx ? "active" : ""}`}
            id={tag[0]}
            key={idx}
            onClick={(e: any) => filterByTags(idx, e.target.id)}
          >
            {tag[0]}({tag[1]})
          </button>
        ))}
      </div>
    )
}