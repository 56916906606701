import { useState } from "react";
import { file, ProjectType } from "../../../types";
import { SwiperCount } from "./SwiperCount";
import { SwiperItem } from "./SwiperItem";
import { SwiperNav } from "./SwiperNav";

export type Props = ProjectType;
export const Swiper = ( {files, selectedFiles}: Props) => {
    var array = selectedFiles.split("\n");
    const fileId: any = array.map((d: any, i: number) =>
      d.replace(/[-+()\s]/g, "")
    );
    const filteredFiles: file[] = fileId
      .map((id: any) => files.filter((f: file) => id === f.id).map((d: any) => d))
      .flat();

    const [current, setCurrent] = useState(0);
    const length: number = filteredFiles && filteredFiles.length;

    return(
        <div className="swiper-container">
            {/* <SwiperNav setCurrent={setCurrent} length={length} current={current} filteredFiles={filteredFiles}></SwiperNav> */}
            <ul className="swiper-container__list">
            <SwiperItem setCurrent={setCurrent} length={length} current={current} filteredFiles={filteredFiles}></SwiperItem>
            </ul>
            <SwiperCount setCurrent={setCurrent} length={length} current={current} filteredFiles={filteredFiles}></SwiperCount>
        </div>
    )
    
      
}